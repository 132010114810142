export const NavigationConfig = {
  sideMenu: [
    {
      header: true,
      title: 'Main Navigation',
      key: 'mainNavigation',
      hidden: true,
      hiddenOnCollapse: true,
    },
    {
      key: 'dashboard',
      title: 'Dashboard',

      icon: 'fa fa-chart-line',
      permId: 'Dashboard',
      child: [
        {
          href: '/',
          key: 'analytics',
          title: 'Analytics',
        },
        {
          href: '/predictions',
          key: 'predictions',
          title: 'Predictions',
        },
        {
          href: '/hardware',
          key: 'hardware',
          title: 'Hardware',
        },
      ],
    },
    {
      key: 'countryManagement',
      title: 'Country Management',
      icon: 'fas fa-flag',
      permId: 'Country Management',
      child: [
        {
          key: 'currencies',
          href: '/currencies',
          title: 'Currencies',
        },
        {
          key: 'countries',
          href: '/countries',
          title: 'Countries',
        },
        {
          key: 'cities',
          href: '/cities',
          title: 'Cities',
        },
      ],
    },
    {
      href: '/organizations',
      key: 'organizations',
      title: 'Organizations',

      permId: 'Organizations',
      icon: 'fa fa-building',
    },
    {
      key: 'fleets',
      title: 'Fleets',

      icon: 'fa fa-map-marked-alt',

      child: [
        {
          key: 'fleets',
          href: '/fleets',
          title: 'Fleets',
          permId: 'Fleets',
        },
        {
          key: 'subscriptionPlans',
          href: '/pricing/subscription-plans',
          title: 'Subscription Plans',
          permId: 'Subscription Plans',
        },
        {
          key: 'fleetPricing',
          href: '/pricing/billing-plans',
          title: 'Fleet Pricing',
          permId: 'Fleet Pricing',
        },
        {
          key: 'rentPricingPlan',
          href: '/pricing/rent-plans',
          title: 'Rent Pricing Plan',
          permId: 'Rent Pricing Plan',
        },
        {
          key: 'taxPlan',
          href: '/pricing/tax-plans',
          title: 'Tax Plans',
          permId: 'Tax Plans',
        },
      ],
    },
    // Org User
    {
      key: 'fleetOperator',
      title: 'Fleet Operator',
      icon: 'fas fa-users',
      child: [
        {
          key: 'allUsers',
          href: '/users',
          title: 'All Users',
          permId: 'Users',
        },
        {
          key: 'operators',
          href: '/users/operators',
          title: 'Operators',
          permId: 'Operators',
        },
        {
          key: 'franchises',
          href: '/users/franchises',
          title: 'Franchises',
          permId: 'Franchises',
        },
        {
          key: 'topupAgents',
          href: '/users/agents',
          title: 'Top Up Agents',
          permId: 'Agents',
        },
        {
          key: 'rentStationOwners',
          href: '/users/rent-station-owners/',
          title: 'Rent Station Owners',
          permId: 'Rent Station Owners',
        },
        {
          key: 'rolesAndPermission',
          href: '/roles',
          title: 'Roles and Permissions',
          permId: 'Roles',
        },
      ],
    },
    {
      key: 'marketplace',
      title: 'Marketplace',
      permId: 'Marketplace',
      icon: 'fa fa-store',
      child: [
        {
          key: 'orders',
          href: '/marketplaces/orders',
          title: 'Orders',
        },
        {
          key: 'buyers',
          href: '/marketplaces/buyers',
          title: 'Buyers',
        },
        {
          key: 'payouts',
          href: '/marketplaces/payouts',
          title: 'Payouts',
        },
      ],
    },
    //sunpod
    {
      key: 'pods',
      title: 'Pods',
      permId: 'Vehicles',
      icon: 'fa fa-bicycle',
      child: [
        {
          href: '/sunpods',
          key: 'sunpods',
          title: 'Sunpods',
        },
        {
          href: '/edgebox',
          key: 'edgebox',
          title: 'Edgebox',
        },
      ],
    },

    // Vehicle
    {
      key: 'vehicles',
      title: 'Vehicles',
      icon: 'fa fa-bicycle',
      child: [
        {
          key: 'vmodels',
          href: '/vehicle-management/vehicle-models',
          title: 'Models',
          permId: 'Vehicles',
        },
        {
          key: 'iotCapabilities',
          href: '/iot-capabilities',
          title: 'IOT Capabilities',
          permId: 'IOT Capabilities',
        },
        {
          key: 'iot',
          href: '/vehicle-management/iot',
          title: 'IOT',
          permId: 'IOT',
        },
        {
          key: 'vehiclesManufacturers',
          href: '/vehicle-management/vehicle-manufacturers',
          title: 'Vehicle Manufacturers',
          permId: 'Vehicle Manufacturers',
        },
        {
          key: 'operationPrimer',
          href: '/vehicle-management/operation-primer',
          title: 'Operation Primer',
          permId: 'Vehicles',
        },
        {
          key: 'chargingDock',
          href: '/vehicle-management/charging-dock',
          title: 'Charging Dock',
          permId: 'Vehicles',
        },
        {
          key: 'bikes',
          href: '/vehicle-management/bikes',
          title: 'Bikes',
          permId: 'Vehicles',
        },
        {
          key: 'ebikes',
          href: '/vehicle-management/e-bikes',
          title: 'E-Bikes',
          permId: 'Vehicles',
        },
        {
          key: 'scooters',
          href: '/vehicle-management/scooters',
          title: 'Scooters',
          permId: 'Vehicles',
        },
        {
          key: 'scootersPro',
          href: '/vehicle-management/scooters-pro',
          title: 'Scooters Pro',
          permId: 'Vehicles',
        },
        {
          key: 'superCocos',
          href: '/vehicle-management/super-cocos',
          title: 'SuperCocos',
          permId: 'Vehicles',
        },
        {
          key: 'kenotas',
          href: '/vehicle-management/kenotas',
          title: 'Kenotas',
          permId: 'Vehicles',
        },
        {
          key: 'mopeds',
          href: '/vehicle-management/mopeds',
          title: 'Mopeds',
          permId: 'Vehicles',
        },
      ],
    },
    // Logs
    {
      key: 'logs',
      title: 'Logs',
      icon: 'fa fa-layer-group',
      child: [
        {
          key: 'signIn',
          href: '/logs/sign-in/',
          title: 'Sign In',
          permId: 'SMS Logs',
        },
        {
          key: 'eventLogs',
          title: 'Event Logs',
          permId: 'Event Logs',
          href: '/event-logs',
        },
      ],
    },

    {
      key: 'livemap',
      // href: '/live-map',
      title: 'Live Map',
      permId: 'Live Map',
      icon: 'far fa-map',
      href: '/live-map',
      // child: [
      //   {
      //     href: '/live-map/old',
      //     title: 'Live Map',
      //     // title: 'Live Map (Old)',
      //   },
      //   // {
      //   //   href: '/live-map/new',
      //   //   title: 'Live Map (New)',
      //   // },
      // ],
    },
    // GEOFence
    {
      key: 'geofence',
      title: 'Geofence',
      icon: 'fa fa-street-view',
      child: [
        {
          key: 'serviceAreas',
          href: '/geofence/service-areas',
          title: 'Service Areas',
          permId: 'Service Areas',
        },
        {
          key: 'parkingAreas',
          href: '/geofence/parking-areas',
          title: 'Parking Areas',
          permId: 'Parking Areas',
        },
        {
          key: 'restrictedAreas',
          href: '/geofence/restricted-areas',
          title: 'Restricted Areas',
          permId: 'Restricted Areas',
        },
        {
          key: 'slowSpeedAreas',
          href: '/geofence/slow-zone-areas',
          title: 'Slow Speed Areas',
          permId: 'Slow Speed Areas',
        },
        {
          key: 'rentStations',
          href: '/geofence/rent-station-areas',
          title: 'Rent Stations',
          permId: 'Rent Areas',
        },
        {
          key: 'sunpodStations',
          href: '/geofence/sunpod-station-areas',
          title: 'Sunpod Stations',
          permId: 'Sun Pod Areas',
        },
        {
          key: 'rewardZones',
          href: '/geofence/reward-zones',
          title: 'Reward Zones',
          permId: 'Reward Zones',
        },
      ],
    },

    // Rider management
    {
      key: 'riders',
      title: 'Riders',
      icon: 'fa fa-biking',
      permId: 'Riders',
      child: [
        {
          key: 'generalRiders',
          href: '/riders',
          title: 'General Riders',
        },
        {
          key: 'blacklistedRiders',
          href: '/blacklisted-riders',
          title: 'Blacklisted Riders',
        },
        {
          key: 'deleteRequestByRiders',
          href: '/delete-request-by-riders',
          title: 'Delete Request',
        },
        {
          key: 'deletedRiders',
          href: '/deleted-riders',
          title: 'Deleted Riders',
        },
      ],
    },
    // Trip management
    {
      key: 'trips',
      title: 'Trips',
      icon: 'fa fa-network-wired',
      child: [
        {
          key: 'trips',
          href: '/trips',
          title: 'Trips',
          permId: 'Trips',
        },
        {
          key: 'rentals',
          href: '/vehicle-rentals',
          title: 'Rentals',
          permId: 'Rentals',
        },
      ],
    },

    {
      key: 'transactions',
      title: 'Transactions',
      icon: 'fa fa-coins',
      permId: 'Transaction',
      child: [
        {
          key: 'revenues',
          href: '/revenues',
          title: 'Revenue',
        },
        {
          key: 'payments',
          href: '/payments',
          title: 'Payment',
        },
        {
          key: 'triggerPayments',
          href: '/trigger-payments',
          title: 'Trigger Payment',
        },
        {
          key: 'refundRequest',
          href: '/refund-requests',
          title: 'Refund Request',
        },
      ],
    },
    //offers
    {
      key: 'offers',
      title: 'Offers',
      icon: 'fa fa-gift',
      child: [
        {
          key: 'subscription',
          title: 'Subscription',
          permId: 'Pass',
          href: '/subscription',
        },
        {
          key: 'topup',
          title: 'Topup',
          permId: 'Pass',
          href: '/topup-offer',
        },
        {
          key: 'promotions',
          title: 'Promotions',
          permId: 'Promotions',
          href: '/promos',
        },
        {
          key: 'voucher',
          href: '/voucher-management',
          title: 'Voucher',
          permId: 'Voucher',
        },
        {
          key: 'marketing',
          title: 'Marketing',
          permId: 'Marketing',
          href: '/marketing-campaigns',
        },
      ],
    },

    // Report
    {
      key: 'reports',
      title: 'Reports',
      icon: 'fa fa-copy',
      href: '/reports',
      permId: 'Reports',
    },

    {
      key: 'tasks',
      title: 'Tasks',
      permId: 'Tasks',
      icon: 'fa fa-tasks',
      href: '/tasks',
    },
    {
      key: 'mailTemplates',
      title: 'Mail Templates',
      permId: 'Mail Templates',
      icon: 'fa fa-envelope',
      href: '/mails',
    },

    {
      key: 'notifications',
      title: 'Notification',
      permId: 'Dashboard',
      icon: 'fa fa-bell',
      href: '/notifications',
    },
    {
      key: 'whatsNew',
      title: `What's New`,
      permId: `What's New`,
      icon: 'fa fa-newspaper',
      href: '/whats-new',
    },

    // Settings
    {
      key: 'settings',
      title: 'Settings',
      permId: 'Settings',
      icon: 'fa fa-cog',
      href: '/settings/general',
    },
  ],
}
