export default {
  api: {
    // get
    index: '/dashboard/reward-zones/',
    // post
    create: '/dashboard/reward-zones/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/reward-zones/${id}/`
    },
    // delete
    delete: (id = 'uuid') => {
      return `/dashboard/reward-zones/${id}/`
    },
    details: (id = 'uuid') => {
      return `/dashboard/reward-zones/${id}/`
    },
  },
  events: {
    name: 'reward-zone',
    // refresh-index-data
    refresh: `rid-reward-zone`,
    // slideover-right
    sorId: 'reward-zone',
    sorOpen: 'sor-open-reward-zone',
    sorClose: 'sor-close-reward-zone',
    sorToggle: 'sor-toggle-reward-zone',
    // editing-data
    editingData: 'edit-reward-zone-data',
    // viewing-data
    viewingData: 'view-reward-zone-data',
  },
}
