export default {
  api: {
    // get
    index: '/dashboard/rent-areas/',
    // post
    create: '/dashboard/rent-areas/',
    // patch
    update: (rentStationId) => `/dashboard/rent-areas/${rentStationId}/`,
    // delete
    delete: (rentStationId) => `/dashboard/rent-areas/${rentStationId}/`,
    // get
    details: (rentStationId) => `/dashboard/rent-areas/${rentStationId}/`,
  },
  events: {
    name: 'rent-station-area',
    // refresh-index-data
    refresh: `rid-rent-station-area`,
    // slideover-right
    sorId: 'rent-station-area',
    sorOpen: 'sor-open-rent-station-area',
    sorClose: 'sor-close-rent-station-area',
    sorToggle: 'sor-toggle-rent-station-area',
    // editing-data
    editingData: 'edit-rent-station-area-data',
    // viewing-data
    viewingData: 'view-rent-station-area-data',
  },
}
