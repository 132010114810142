export const DropdownConfig = {
  api: {
    // get
    country: '/dashboard/countries/?dropdown',
    // get
    city: (countryId = undefined) => {
      let endpoint = '/dashboard/cities/?dropdown'
      if (countryId) {
        endpoint += `&country=${countryId}`
      }
      return endpoint
    },
    // get
    currency: (countryId = undefined) => {
      let endpoint = '/dashboard/currencies/?dropdown'
      if (countryId) {
        endpoint += `&country=${countryId}`
      }
      return endpoint
    },
    // get
    subscription: '/dashboard/subscriptions/?dropdown',
    // get
    paymentGateway: '/dashboard/payment-gateways/',

    // get
    billingFleets: (pricing_bike_category = undefined) => {
      let endpoint = '/dashboard/fleets/?dropdown&unassigned=pricing'
      if (pricing_bike_category) {
        endpoint += `&pricing_bike_category=${pricing_bike_category}`
      }
      return endpoint
    },
    // get
    taxPlans: '/dashboard/tax-plans/?dropdown',

    // get
    serviceAreaFleets: '/dashboard/fleets/?unassigned=geofence',

    // get
    serviceAreaSpeedLimits: '/dashboard/speed-limits/',
    googleMapAddress(place_id, fields, key) {
      let endpoint = 'https://maps.googleapis.com/maps/api/place/details/json?'
      endpoint += `place_id=${place_id}&`
      endpoint += `fields=${fields}&`
      endpoint += `key=${key}&`
      return endpoint
    },

    // get
    serviceAreas: '/dashboard/service-areas/?dropdown',

    organizationCurrencies: '/dashboard/org-currencies/',
  },
}
