<template>
  <div
    id="app"
    class="h-full min-h-screen antialiased text-gray-900 bg-gray-100"
  >
    <router-view />
  </div>
</template>

<script>
import LogRocket from 'logrocket'

export default {
  name: 'App',
  components: {},
  created() {
    LogRocket.init('13vyks/oto-admin-dashboard')
    //-prompt for audio and video
    // navigator.mediaDevices.getUserMedia({ video: false, audio: true })
    // console.log('------VID LOG INIT------')
  },
  mounted() {
    const userInfo = this.$store.getters['auth/userInfo']
    // console.log('------VID LOGS HIT------')
    // console.log(userInfo)
    if (userInfo) {
      LogRocket.identify(userInfo.id, {
        name: `${userInfo.full_name}`,
        email: `${userInfo.email}`,

        //custom user variables
        // link: `${window.location.href}`,
        superadmin: userInfo.is_superadmin,
        phoneNumber: `${userInfo.phone_number}`,
      })
    }
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters['auth/status']
    },
  },
  // todo: implement axios interceptor
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap"); */
html {
  margin-left: calc(100vw - 100%);
}
#app {
  font-family: 'Roboto', sans-serif;
}
</style>
