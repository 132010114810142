import { DropdownConfig } from '@/config/DropdownConfig'
import { httpClient } from '@/services'

export default {
  init(
    { commit },
    {
      fstId = 'fstId',
      reset = true,
      endpoint = '/',
      qso = { prepend: '?', append: '' },
      currencyEnabled = false,
      currencyAttributes = {},
    } = {}
  ) {
    commit('setId', fstId)
    reset && commit('reset', fstId)

    commit('setQso', qso)
    commit('setEndpoint', endpoint)

    currencyEnabled && commit('setCurrency', { flag: true })
    currencyEnabled && commit('setCurrencyAttributes', currencyAttributes)
  },

  async fetchData({ commit, state, getters }, qs) {
    const url =
      typeof qs === 'string'
        ? state.endpoint + qs
        : state.endpoint + getters.getQueryString
    commit('reqBusy', true)

    if (!state.organizationCurrency.selected && state.currency.flag) {
      try {
        const orgCurrencyResponse = await httpClient.get(
          DropdownConfig.api.organizationCurrencies
        )
        commit('updateOrganizationCurrency', {
          all: orgCurrencyResponse.data,
          selected: orgCurrencyResponse.data.filter((x) => x.is_preferred)[0]
            .id,
        })
      } catch (err) {
        console.log(err)
      }
    }

    try {
      if (url) {
        const query = url.split('?')[1]
        const haveCurrencyInQS = query
          .split('&')
          .find((s) => s.includes('preferred_currency'))
          ? true
          : false
        if (haveCurrencyInQS === true && state.currency.flag === true) {
          const res = await httpClient.get(url)
          commit('setResponse', res.data)
          console.log('fetch-try-res-true', res.data, 'qs', query)
          commit('reqBusy', false)
          commit('setListSelectAllStatus', false)
          return Promise.resolve(res)
        }
        if (haveCurrencyInQS === false && state.currency.flag === false) {
          const res = await httpClient.get(url)
          commit('setResponse', res.data)
          console.log('fetch-try-res-false', res.data, 'qs', query)
          commit('setListSelectAllStatus', false)
          commit('reqBusy', false)
          return Promise.resolve(res)
        }
      }
    } catch (err) {
      console.error(err, state.endpoint + qs)
      // todo: create setError() mutation
      // commit('setError', err)
      return Promise.reject(err)
    }
  },

  async switchCurrency({ commit, dispatch }, targetCurrency) {
    commit('updateOrganizationCurrency', {
      selected: targetCurrency,
    })
    dispatch('fetchData')
  },
  syncBulkSummary({ state, commit }, isReset = false) {
    if (isReset) {
      commit('setBulkSummary', {
        totalSummary: 0,
        processingSummary: 0,
        succeededSummary: 0,
        failedSummary: 0,
      })
      return
    }
    const totalSummary = Object.keys(state.bulkItemHWALogs).length
    const processingSummary = Object.keys(state.bulkItemHWALogs).filter(
      (id) => state.bulkItemHWALogs[id].type === 'processing'
    ).length
    const succeededSummary = Object.keys(state.bulkItemHWALogs).filter(
      (id) => state.bulkItemHWALogs[id].type === 'succeeded'
    ).length
    const failedSummary = Object.keys(state.bulkItemHWALogs).filter(
      (id) => state.bulkItemHWALogs[id].type === 'failed'
    ).length
    commit('setBulkSummary', {
      totalSummary,
      processingSummary,
      succeededSummary,
      failedSummary,
    })
  },
}
