/**
 * OTORide specific utils only
 * Add doc blocks
 */

// Trip related

export function getTripStatusColor(flag) {
  let colorsMap = {
    R: 'blue',
    A: 'red',
    U: 'yellow',
    O: 'purple',
    V: 'orange',
    C: 'green',
    T: 'red',
  }

  return flag in colorsMap ? colorsMap[flag] : 'fallback'
}

export function getTripStatusText(flag) {
  const aliases = {
    R: 'Reserved',
    A: 'Cancelled',
    U: 'Starting Trip',
    O: 'On Rent',
    V: 'Forced Cancel',
    C: 'Completed',
    T: 'Time Out',
  }

  return flag in aliases ? aliases[flag] : '--'
}

// Vehicle related

export function getVehicleCategoryByFlag(flag) {
  let aliases = {
    P: 'Scooter',
    S: 'Bike',
    E: 'EBike',
  }
  return flag in aliases ? aliases[flag] : '--'
}

export function getVehicleDomainPortByFlag(flag) {
  let aliases = {
    P: '8192',
    S: '8193',
    E: '8194',
  }
  return flag in aliases ? aliases[flag] : '--'
}

export function getPromoCategoryName(type, fallback = '--') {
  const map = new Map([
    ['RD', 'Discount'],
    ['FD', 'Flat Discount'],
    ['FR', 'Free Ride'],
  ])
  return map.get(type?.toString()?.toUpperCase()) || fallback
}

export function getPassCategoryName(type, fallback = '--') {
  const map = new Map([
    ['DD', 'Daily'],
    ['WE', 'Weekly'],
    ['MM', 'Monthly'],
    ['YY', 'Yearly'],
  ])
  return map.get(type?.toString()?.toUpperCase()) || fallback
}

export function getDate(date) {
  let result = ''
  var arr_month = new Array()
  arr_month[0] = 'Jan'
  arr_month[1] = 'Feb'
  arr_month[2] = 'Mar'
  arr_month[3] = 'Apr'
  arr_month[4] = 'May'
  arr_month[5] = 'Jun'
  arr_month[6] = 'Jul'
  arr_month[7] = 'Aug'
  arr_month[8] = 'Sep'
  arr_month[9] = 'Oct'
  arr_month[10] = 'Nov'
  arr_month[11] = 'Dec'

  var time_body = new Date(date)

  let year = time_body.getFullYear()
  let month_idx = time_body.getMonth()
  let day = time_body.getDate()
  var month2 = arr_month[month_idx]
  if (day && month2 && year) {
    result = day + ' ' + month2 + ' ' + year
    return result
  } else {
    return '--'
  }
}

export function dateZuluFormatter(date) {
  if (date) {
    const strDate =
      date.split('/')[2] +
      '-' +
      date.split('/')[1] +
      '-' +
      date.split('/')[0] +
      'T00:00:00Z'
    return strDate
  } else {
    return '--'
  }
}

export function getInvoiceStatus(opt, fallback = '--') {
  const map = {
    C: 'Complete',
    P: 'Pending',
  }
  return map[opt] || fallback
}

export function getVehicleEnabledFlags({
  general,
  operational,
  status,
  locked = {},
  unlocked = {},
  inspection = {},
  batterySwap = {},
  docked_charging_adapter,
}) {
  let flags = {
    ...general,
    ...operational,
    ...status,
    ...locked,
    ...unlocked,
    ...inspection,
    ...batterySwap,
    docked_charging_adapter,
  }

  let enabled = []

  for (const key in flags) {
    if (flags[key] === true) {
      enabled.push(key)
    }
  }

  return enabled
}

export function getVehicleFlagAlias(flag) {
  const aliases = {
    charging_pick: 'Charging Pick',
    maintainance: 'On Maintenance',
    rebalance: 'Rebalance',
    swap_battery: 'Swap Battery',
    geo_fence_alert: 'Geofence Alert',
    iot_fault: 'IOT Fault',
    low_battery: 'low Battery',
    missing: 'Missing',

    is_charging: 'On Charging',
    is_parking: 'On Parking',
    is_reserved: 'On Reservation',
    is_on_ride: 'On Ride',
    is_locked: 'Locked',
    is_unlocked: 'Unlocked',

    inspection_required: 'Inspection Required',
    re_inspection: 'Reinspection Required',
    under_inspection: 'Under Inspection',
    repair_required: 'Repair Required',
    repair_backlog: 'Repair Backlog',
    battery_swap_required: 'Battery Swap Required',
    battery_swap_in_progress: 'Battery Swap In Progress',
    docked_charging_adapter: 'Docked',

    none: 'None',
  }
  return flag in aliases ? aliases[flag] : '--'
}

export const getIotTypes = () => ({
  OMNI_BIKE: 'Omni Bike',
  OMNI_SCOOTER: 'Omni Scooter',
  OMNI_CABLE_SCOOTER: 'Omni Cable Scooter',
  OMNI_EBIKE: 'Omni Ebike',
  TELTONIKA_TFT_100: 'Teltonika TFT100',
  TELTONIKA_TST_100: 'Teltonika TST100',
  OKAI_ES200B: 'Okai EBike',
  OKAI_ES400B: 'Okai Scooter',
  SEGWAY_MAXPRO: 'Segway MaxPro',
})

export const getIotTypeOptions = () => [
  { value: 'OMNI_BIKE', text: 'Omni Bike' },
  { value: 'OMNI_SCOOTER', text: 'Omni Scooter' },
  { value: 'OMNI_CABLE_SCOOTER', text: 'Omni Cable Scooter' },
  { value: 'OMNI_EBIKE', text: 'Omni Ebike' },
  { value: 'TELTONIKA_TFT_100', text: 'Teltonika TFT100' },
  { value: 'TELTONIKA_TST_100', text: 'Teltonika TST100' },
  { value: 'OKAI_ES200B', text: 'Okai EBike' },
  { value: 'OKAI_ES400B', text: 'Okai Scooter' },
  { value: 'SEGWAY_MAXPRO', text: 'Segway MaxPro' },
]

export const disputeStatusMap = {
  warning_needs_response: { badge: 'alt-orange', text: 'Needs Response' },
  warning_under_review: { badge: 'alt-orange', text: 'Under Review' },
  warning_closed: { badge: 'alt-orange', text: 'Closed' },

  needs_response: { badge: 'alt-blue', text: 'Needs Response' },
  under_review: { badge: 'alt-blue', text: 'Under Review' },

  charge_refunded: { badge: 'alt-green', text: 'Charge Refunded' },
  won: { badge: 'alt-green', text: 'Won' },

  lost: { badge: 'alt-red', text: 'Lost' },
}

// or use Object.keys(disputeStatusMap)
export const disputeStatusList = [
  'warning_needs_response',
  'warning_under_review',
  'warning_closed',
  'needs_response',
  'under_review',
  'charge_refunded',
  'won',
  'lost',
]

export const getDisputeStatus = (disputeStatus) => {
  return disputeStatusMap[disputeStatus] ?? { badge: 'alt-gray', text: '--' }
}
