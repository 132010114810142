/*
 * This is an otoride specific custom plugin
 *
 * Farhan Israq <ishraaq21@gmail.com>
 */

// list of current user permissions with actions
const userPermissionsWithActions = () => {
  let permissionActions = []

  const warn = 'No permission actions has been set'
  try {
    permissionActions = JSON.parse(localStorage.getItem('permissions'))
  } catch {
    console.warn(warn)
  }

  if (!Array.isArray(permissionActions)) {
    console.warn(warn)
  }

  return permissionActions
}

console.log('userPermissionsWithActions', userPermissionsWithActions())

const filterByAction = (action, permissions = userPermissionsWithActions()) => {
  return permissions.filter((p) => p?.action_types?.includes(action))
}

const hasPermissionAction = (
  action,
  permission,
  permissions = userPermissionsWithActions()
) => {
  return permissions.some(
    (p) =>
      p?.name?.toLowerCase() === permission?.toLowerCase() &&
      p?.action_types?.includes(action.toUpperCase())
  )
}

const hasAnyPermissionAction = (
  actions,
  permission,
  permissions = userPermissionsWithActions()
) => {
  let actionsArray = Array.isArray(actions) ? actions : actions.split('|')

  return actionsArray.some((action) =>
    hasPermissionAction(action, permission, permissions)
  )
}

const hasAllPermissionAction = (
  actions,
  permission,
  permissions = userPermissionsWithActions()
) => {
  let actionsArray = Array.isArray(actions) ? actions : actions.split('|')
  return actionsArray.every((action) =>
    hasPermissionAction(action, permission, permissions)
  )
}

const canCreate = (permission, permissions = userPermissionsWithActions()) => {
  return hasPermissionAction('CREATE', permission, permissions)
}

const canNotCreate = (
  permission,
  permissions = userPermissionsWithActions()
) => {
  return !canCreate(permission, permissions)
}

const canRead = (permission, permissions = userPermissionsWithActions()) => {
  return hasPermissionAction('VIEW', permission, permissions)
}

const canNotRead = (permission, permissions = userPermissionsWithActions()) => {
  return !canRead(permission, permissions)
}

// alias to canRead
const canView = (permission, permissions = userPermissionsWithActions()) => {
  return canRead(permission, permissions)
}

const canNotView = (permission, permissions = userPermissionsWithActions()) => {
  return !canView(permission, permissions)
}

const canUpdate = (permission, permissions = userPermissionsWithActions()) => {
  return hasPermissionAction('UPDATE', permission, permissions)
}

const canNotUpdate = (
  permission,
  permissions = userPermissionsWithActions()
) => {
  return !canUpdate(permission, permissions)
}

const canDelete = (permission, permissions = userPermissionsWithActions()) => {
  return hasPermissionAction('DELETE', permission, permissions)
}

const canNotDelete = (
  permission,
  permissions = userPermissionsWithActions()
) => {
  return !canDelete(permission, permissions)
}

const notAbleToToast = ({ action = '', permission = '' } = {}) => ({
  group: 'bottomRight',
  type: 'error',
  title: 'Permission Error',
  text: `You do not have enough permission ${
    action ? 'to ' + action.toLowerCase() : 'to perform'
  } ${permission ? ' ' + permission.toLowerCase() : 'this operation'}`,
})

export const $acl = {
  permissionActions: userPermissionsWithActions(),
  filterByAction,
  hasPermissionAction,
  hasAnyPermissionAction,
  hasAllPermissionAction,
  canCreate,
  canNotCreate,
  canRead,
  canNotRead,
  canView,
  canNotView,
  canUpdate,
  canNotUpdate,
  canDelete,
  canNotDelete,
  //
  notAbleToToast,
}

// console.log($acl.filterByAction('VIEW'))
// console.log('hasPermissionAction:', $acl.hasPermissionAction('view', 'users'))
// console.log(
//   'hasAnyPermissionAction:',
//   $acl.hasAnyPermissionAction('view|update', 'USERS')
// )
// console.log(
//   'hasAllPermissionAction:',
//   $acl.hasAllPermissionAction('view|update', 'USERS')
// )
// console.log('canCreate:', $acl.canCreate('users'))
// console.log('canRead:', $acl.canRead('users'))
// console.log('canView:', $acl.canView('users'))
// console.log('canUpdate:', $acl.canUpdate('users'))
// console.log('canDelete:', $acl.canDelete('users'))

// vue plugin

export const AclPlugin = {
  install(Vue) {
    Vue.prototype.$acl = $acl
  },
}

import Vue from 'vue'
Vue.use(AclPlugin)
