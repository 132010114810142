// everything should be a function, even if it's not necessary,
// just to keep it consistent
// avoid adding query-strings unless it's required

export const endpoints = {
  // baseURL: process.env.VUE_APP_BASE_APP_URL + '/dashboard',
  baseURL: process.env.VUE_APP_BASE_APP_URL,

  auth: {
    login: () => '/dashboard/admin-login/',
    activate: () => '/dashboard/org-users/activate/',
    updatePassword: () => '/dashboard/org-users/update-password/',
  },

  user: {
    agent: {
      // get
      index: () => '/dashboard/agents/',
      // get
      topupHistory: (id) => `/dashboard/agents/${id}/topup-history/`,
      // get
      incomingHistory: (id) => `/dashboard/agents/${id}/incoming-history/`,
      // post
      balanceTransfer: (id) => `/dashboard/agents/${id}/transfer-balance/`,
    },

    franchise: {
      // get
      index: () => '/dashboard/franchises/',
      // post
      create: () => '/dashboard/franchises/',
      // patch
      update: (id) => `/dashboard/franchises/${id}/`,
    },

    operator: {
      // get
      index: () => '/dashboard/operators/',
      // get
      single: (id) => `/dashboard/org-users/${id}/`,
      // post
      create: () => `/dashboard/operators/`,
      // patch
      update: (id) => `/dashboard/operators/${id}/`,
      // put
      updateStatus: (id) => `/org-users/${id}/update-status/`,
    },

    // note: staff users are the org-users
    staff: {
      // get
      index: () => '/dashboard/org-users/',
      // get
      single: (staffId) => `/dashboard/org-users/${staffId}/`,
      // post
      create: '/dashboard/org-users/',
      // patch
      update: (staffId) => `/dashboard/org-users/${staffId}/`,

      payment_create: (staffId) =>
        `/dashboard/org-users/${staffId}/payment-info/`,
      document_create: (staffId) =>
        `/dashboard/org-users/${staffId}/document-info/`,
      address_create: (staffId) =>
        `/dashboard/org-users/${staffId}/address-info/`,

      // post
      verifyDocs: (staffId) => `/dashboard/org-users/${staffId}/document-info/`,

      // post
      activate: () => `/dashboard/org-users/activate/`,
      // put
      updateStatus: (staffId) =>
        `/dashboard/org-users/${staffId}/update-status/`,
      // get
      pointIndex: (riderId) =>
        `/dashboard/org-users/${riderId}/behavior-points/`,
    },
  },

  rider: {
    // get
    index: () => '/dashboard/riders/',
    // get
    single: (riderId) => `/dashboard/riders/${riderId}/`,
    // get
    paymentInfo: (riderId) => `/dashboard/riders/${riderId}/payment-info/`,
    // post
    balanceAdjustment: (riderId) => `/dashboard/riders/${riderId}/`,
    // get
    balanceAdjustmentLogs: (riderId) =>
      `/dashboard/riders/${riderId}/balance-adjustment-records/`,
  },

  organization: {
    // get
    index: () => '/dashboard/organizations/',
    // get
    single: (id) => `/dashboard/organizations/${id}/`,
    // post [add-step-1]
    create: () => '/dashboard/organizations/',
    // patch [edit|add-step-2]
    update: (id) => `/dashboard/organizations/${id}/`,

    vehicleDomain: {
      // get
      index: (orgId) =>
        `/dashboard/organizations/${orgId}/vehicle-type-settings/`,
      // post
      create: (orgId) =>
        `/dashboard/organizations/${orgId}/vehicle-type-settings/`,
      // patch
      update: (configId) =>
        `/dashboard/organizations/vehicle-type-settings/${configId}/`,
    },
  },

  // todo: move to user?
  identityCard: {
    update: (id) => `/dashboard/identity-cards/${id}/`,
  },

  analytics: {
    gauge_chart: () => '/dashboard/analytics/gauge-chart/',
    bar_chart: () => '/dashboard/analytics/bar-chart/',
    map_chart: () => '/dashboard/analytics/map-chart/',
    line_chart: () => '/dashboard/analytics/line-chart/',
    activity_chart: () => '/dashboard/analytics/activity-chart/',

    trip_heatmap: () => '/dashboard/analytics/demand-heatmap/',
  },

  pricingPlan: {
    // get
    index: () => '/dashboard/pricing-plans/',
    // get
    details: (id) => `/dashboard/pricing-plans/${id}/`,
    // post
    create: () => '/dashboard/pricing-plans/',
    // patch
    update: (id) => `/dashboard/pricing-plans/${id}/`,
  },

  // todo: to func
  subscriptionPlan: {
    // get
    index: '/dashboard/subscriptions/',
    // post
    create: '/dashboard/subscriptions/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/subscriptions/${id}/`
    },
  },

  // todo: toFunc
  taxPlan: {
    // get
    index: '/dashboard/tax-plans/',
    // post
    create: '/dashboard/tax-plans/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/tax-plans/${id}/`
    },
  },

  // transactions
  // todo: toFunc
  invoice: {
    // get
    index: '/dashboard/invoices/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/invoices/?trip=${id}`
    },
    // post
    create: '/dashboard/invoices/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/invoices/${id}/`
    },
    // post
    refund: (id = 'invoice-uuid') => {
      return `/dashboard/invoices/${id}/refund/`
    },
  },

  fleet: {
    // get
    index: () => '/dashboard/fleets/',
    // get
    indexOfStaff: () => (userId = 'uuid') => {
      return `/dashboard/fleets/?org_user=${userId}&`
    },
    // post
    create: () => '/dashboard/fleets/',
    // patch
    update: (fleetId) => `/dashboard/fleets/${fleetId}/`,
    // get
    details: (fleetId) => `/dashboard/fleets/${fleetId}/`,
    // delete
    delete: (fleetId) => `/dashboard/fleets/${fleetId}/`,

    operator: {
      // get & patch
      charging: (fleetId) =>
        `/dashboard/fleets/${fleetId}/operator-task-charging-settings/`,
      // get & patch
      rebalance: (fleetId) =>
        `/dashboard/fleets/${fleetId}/operator-task-rebalancing-settings/`,
    },
  },

  lock: {
    // get
    index: () => '/dashboard/locks/',
    // post
    create: () => '/dashboard/locks/',
    // patch
    update: (id) => `/dashboard/locks/${id}/`,
  },

  // todo: toFunc
  vehicle: {
    // get
    index: () => '/dashboard/vehicles/',
    // get || deprecated -> pass query string instead
    indexOfFranchise: (userId = 'uuid') => {
      return `/dashboard/vehicles/?franchise_user=${userId}&`
    },
    // get
    details: (id) => `/dashboard/vehicles/${id}/`,
    // post
    create: () => '/dashboard/vehicles/',
    // delete
    delete: (vehicleId) => `/dashboard/vehicles/${vehicleId}/`,
    // patch
    update: (id) => `/dashboard/vehicles/${id}/`,
    // patch
    updateGeneralFLags: (id) => `/dashboard/vehicles/${id}/general-flags/`,
    // patch
    updateOperationalFLags: (id) =>
      `/dashboard/vehicles/${id}/operational-flags/`,
    // patch
    updateControlFLags: (id) => `/dashboard/vehicles/${id}/control-flags/`,
    // patch
    updateStatusFLags: (id) => `/dashboard/vehicles/${id}/status-flags/`,
    // post
    ringAlarm: (id = 'uuid') => `/dashboard/vehicles/${id}/ring-alarm/`,
    triggerRing: (id = 'uuid') => `/dashboard/vehicles/${id}/ring-alarm/`,
    // post
    bulkUpdateStatus: () => `/dashboard/vehicles/bulk-status-update/`,
    // get
    logs: (id = 'uuid') => `/dashboard/vehicles/${id}/logs/`,
    // get
    powerLevel: (vehicleId, startDate, endDate) =>
      `/dashboard/vehicles/${vehicleId}/power-level-chart/?start_date=${startDate}&end_date=${endDate}`,
    // get
    healthChart: (vehicleId, startDate, endDate) =>
      `/dashboard/vehicles/${vehicleId}/health-charts/?start_date=${startDate}&end_date=${endDate}`,
    status: {
      current: (vehicleId) => `/dashboard/vehicles/${vehicleId}/status/`,
      // post
      lock: (vehicleId) => `/dashboard/vehicles/${vehicleId}/lock/`,
      // post
      unlock: (vehicleId) => `/dashboard/vehicles/${vehicleId}/unlock/`,
      // post
      forceLock: (vehicleImei, vehicleType) =>
        `/dashboard/${vehicleType}/${vehicleImei}/force-lock/`,
      // post
      forceUnlock: (vehicleImei, vehicleType) =>
        `/dashboard/${vehicleType}/${vehicleImei}/force-unlock/`,
      // post
      forceSync: (vehicleId) =>
        `/dashboard/vehicles/${vehicleId}/force-status-sync/`,
    },
  },

  singleScooter: {
    // get
    index: '/dashboard/organizations/',
    // get
    single_lock: (id = 'uuid') => {
      return `/dashboard/locks/${id}/`
    },
    single_vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    // post [add-step-1]
    iot: {
      // assigned & unassigned dropdown
      dropdown: `/dashboard/locks/?dropdown`,
      // unassigned dropdown
      unassigned: `/dashboard/locks/?dropdown&not_assigned`,
    },
    create: '/dashboard/organizations/',
    create_iot: '/dashboard/locks/',
    update_iot: (id = 'uuid') => {
      return `/dashboard/locks/${id}/`
    },

    create_vehicle: '/dashboard/vehicles/',
    update_vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    fleets: '/dashboard/fleets/',
    // patch [edit|add-step-2]
    update: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    iot_manufacture: '/dashboard/lock-manufacturers/',
    vehicle_manufacture: '/dashboard/vehicle-manufacturers/',
    iot_index: '/dashboard/locks/',
    summary: {},
    profile: {},
    // fleets: {
    //   // todo: merge?
    //   // get
    //   index: "/dashboard/fleets/",
    //   create: ""
    // }
  },

  geoFence: {
    parkingArea: {
      // get
      index: () => '/dashboard/parking-areas/',
      // post
      create: () => '/dashboard/parking-areas/',
      // patch
      update: (parkingAreaId) => `/dashboard/parking-areas/${parkingAreaId}/`,
      // delete
      delete: (parkingAreaId) => `/dashboard/parking-areas/${parkingAreaId}/`,
      // get
      details: (parkingAreaId) => `/dashboard/parking-areas/${parkingAreaId}/`,
    },

    restrictedArea: {
      // get
      index: () => '/dashboard/restricted-areas/',
      // post
      create: () => '/dashboard/restricted-areas/',
      // patch
      update: (id) => `/dashboard/restricted-areas/${id}/`,
      // get
      details: (id) => `/dashboard/restricted-areas/${id}/`,
      // delete
      delete: (id) => `/dashboard/restricted-areas/${id}/`,
    },

    serviceArea: {
      // get
      index: () => '/dashboard/service-areas/',
      // post
      create: () => '/dashboard/service-areas/',
      // patch
      update: (sId) => `/dashboard/service-areas/${sId}/`,
      // delete
      delete: (sId) => `/dashboard/service-areas/${sId}/`,
      // get
      details: (sId) => `/dashboard/service-areas/${sId}/`,
      // get
      childIndex: (sId) => `/dashboard/service-areas/${sId}/child-areas/`,
    },

    // todo: to func
    slowSpeedArea: {
      // get
      index: '/dashboard/slow-zone-areas/',
      // post
      create: '/dashboard/slow-zone-areas/',
      // get
      details: (id = 'uuid') => {
        return `/dashboard/slow-zone-areas/${id}/`
      },
      // patch
      update: (id = 'uuid') => {
        return `/dashboard/slow-zone-areas/${id}/`
      },
      // delete
      delete: (id = 'uuid') => {
        return `/dashboard/slow-zone-areas/${id}/`
      },
    },
  },

  // todo: toFunc
  trip: {
    // get
    index: '/dashboard/trips/',
    // post
    create: '/dashboard/trips/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/trips/${id}/`
    },
    single: (id = 'uuid') => {
      return `/dashboard/trips/${id}/`
    },
    vehicleTimelineLog: (id = 'uuid') => {
      return `/dashboard/trips/${id}/vehicle-logs/`
    },
    userTimelineLog: (id = 'uuid') => {
      return `/dashboard/trips/${id}/user-logs/`
    },
    actions: {
      cancel: (tripId = 'uuid') => {
        return `/dashboard/trips/${tripId}/cancel/`
      },
      complete: (tripId = 'uuid') => {
        return `/dashboard/trips/${tripId}/complete/`
      },
    },
  },

  pass: {
    // get
    index: () => '/dashboard/passes/',
    // post
    create: () => '/dashboard/passes/',
    // patch
    update: (passId) => `/dashboard/passes/${passId}/`,
    // get
    details: (passId) => `/dashboard/passes/${passId}/`,
  },

  promo: {
    // get
    index: () => '/dashboard/promotions/',
    // post
    create: () => '/dashboard/promotions/',
    // patch
    update: (promoId) => `/dashboard/promotions/${promoId}/`,
    // get
    details: (promoId) => `/dashboard/promotions/${promoId}/`,
    // get
    ofUser: (userId) => `/dashboard/promotions/${userId}/user-promotions/`,
  },

  paymentGateway: {
    // post [create|org-add-step-3]
    create: '/dashboard/payment-gateway-configs/',
    // post [update|org-edit-step-3]
    update: (id = 'id') => {
      return `/dashboard/payment-gateway-configs/${id}/`
    },
  },

  role: {
    // get
    index: () => '/dashboard/roles/',
    // post
    create: () => '/dashboard/roles/',
    // patch
    update: (roleId) => `/dashboard/roles/${roleId}/`,
    // get
    details: (roleId) => `/dashboard/roles/${roleId}/`,
  },

  permission: {
    // get
    index: '/dashboard/permissions/',
    // post
  },

  report: {
    // get
    index: () => '/dashboard/reports/',
    // post
    create: () => '/dashboard/reports/',
    // patch
    update: (reportId) => `/dashboard/reports/${reportId}/`,
    // get
    details: (reportId) => `/dashboard/reports/${reportId}/`,
  },

  // todo: toFunc
  settings: {
    basic: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/basic-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/basic-settings/`,
    },

    email: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/mail-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/mail-settings/`,
    },

    vehicle: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/vehicle-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/vehicle-settings/`,
    },

    event: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/event-settings/`,
      // patch
      update: (eventId) => `/dashboard/event-settings/${eventId}/`,
    },

    general: {
      // get
      index: (orgId) => `/dashboard/organizations/${orgId}/general-settings/`,
      // patch
      update: (orgId) => `/dashboard/organizations/${orgId}/general-settings/`,
    },

    point: {
      // get
      index: () => `/dashboard/organization-behavior-points/`,
      // patch
      update: (orgId) => `/dashboard/organization-behavior-points/${orgId}/`,
      // get
      details: (orgId) => `/dashboard/organization-behavior-points/${orgId}/`,
    },

    payment: {
      gateway: {
        // get
        index: () => `/dashboard/payment-gateways/`,
        // patch
        update: function(id = 'uuid') {
          return `/dashboard/payment-gateway-configs/${id}/`
        },
      },
      topup: {
        // get
        index(id = 'uuid') {
          return `/dashboard/organizations/${id}/topup-settings/`
        },
        // patch
        update(id = 'uuid') {
          return `/dashboard/organizations/${id}/topup-settings/`
        },
      },
    },

    operator: {
      charging: {
        // get
        index: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-charging-settings/`
        },
        // patch
        update: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-charging-settings/`
        },
      },
      rebalancing: {
        // get
        index: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-rebalancing-settings/`
        },
        // patch
        update: function(id = 'uuid') {
          return `/dashboard/organizations/${id}/operator-task-rebalancing-settings/`
        },
      },
    },

    businessHour: {
      // get
      index: function(id = 'uuid') {
        return `/dashboard/organizations/${id}/business-hour-settings/`
      },
      // patch
      update: function(id = 'uuid') {
        return `/dashboard/business-hour-settings/${id}/`
      },
    },
  },

  // -----------------
  // collections
  // -----------------
  dropdown: {
    // get
    country: () => '/dashboard/countries/?dropdown',
    // get
    city: (countryId) => {
      let endpoint = '/dashboard/cities/?dropdown'
      countryId && (endpoint += `&country=${countryId}`)
      return endpoint
    },
    // get
    currency: (countryId) => {
      let endpoint = '/dashboard/currencies/?dropdown'
      countryId && (endpoint += `&country=${countryId}`)
      return endpoint
    },
    // get
    subscription: () => '/dashboard/subscriptions/?dropdown',
    // get
    paymentGateway: () => '/dashboard/payment-gateways/',
    // get
    billingFleets: (pricing_bike_category) => {
      let endpoint = '/dashboard/fleets/?dropdown&unassigned=pricing'
      pricing_bike_category &&
        (endpoint += `&pricing_bike_category=${pricing_bike_category}`)
      return endpoint
    },
    // get
    taxPlans: () => '/dashboard/tax-plans/?dropdown',
    // get
    serviceAreaFleets: () => '/dashboard/fleets/?unassigned=geofence',
    // get
    serviceAreaSpeedLimits: () => '/dashboard/speed-limits/',
    // get
    googleMapAddress(place_id, fields, key) {
      let endpoint = 'https://maps.googleapis.com/maps/api/place/details/json?'
      endpoint += `place_id=${place_id}&`
      endpoint += `fields=${fields}&`
      endpoint += `key=${key}&`
      return endpoint
    },
    // get
    serviceAreas: () => '/dashboard/service-areas/?dropdown',
  },

  status: {
    // patch
    user: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    orgUser: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    operator: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch
    rider: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/update-status/`
    },
    // patch -> @key:status -> @val:A/D/I
    organization: (id = 'uuid') => {
      return `/dashboard/organizations/${id}/`
    },
    // patch -> @key:status -> @val:A/D/I
    vehicle: (id = 'uuid') => {
      return `/dashboard/vehicles/${id}/`
    },
    // patch
    fleet: (id = 'uuid') => {
      return `/dashboard/fleets/${id}/`
    },
  },
}
