export default {
  api: {
    // get
    index: '/dashboard/parking-areas/',
    // post
    create: '/dashboard/parking-areas/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/parking-areas/${id}/`
    },
    // delete
    delete: (id = 'uuid') => {
      return `/dashboard/parking-areas/${id}/`
    },
    // get
    details: (id = 'uuid') => {
      return `/dashboard/parking-areas/${id}/`
    },
    operationModeChoices: () => {
      return `/dashboard/parking-area-operation-mode-choices/`
    },
  },
  events: {
    name: 'parking-area',
    // refresh-index-data
    refresh: `rid-parking-area`,
    // slideover-right
    sorId: 'parking-area',
    sorOpen: 'sor-open-parking-area',
    sorClose: 'sor-close-parking-area',
    sorToggle: 'sor-toggle-parking-area',
    // editing-data
    editingData: 'edit-parking-area-data',
    // viewing-data
    viewingData: 'view-parking-area-data',
  },
}
