import i18n from '@/plugins/i18n'

export const STEP_TOPIC = {
  AI: 'syncAI',
  STARTING_BUSINESS: 'startingBusiness',
}
export const API_STEP_KEY = {
  [STEP_TOPIC.AI]: {
    BRAIN_ID: 'brain-id',
    API_KEY: 'api-key',
  },
  [STEP_TOPIC.STARTING_BUSINESS]: {
    SMS_GATEWAY: 'sms-gateway',
    GOOGLE_MAPS: 'google-maps',
    FLEET: 'fleet',
    DEFAULT_FLEET: 'default-fleet',
    SERVICE_AREA: 'service-area',
    PRICING_PLAN: 'pricing-plan',
    VEHICLES: 'vehicles',
  },
}

export const STEP_SUGGESTIONS = {
  [STEP_TOPIC.AI]: [
    {
      id: `${STEP_TOPIC.AI}-1`,
      key: `${API_STEP_KEY[STEP_TOPIC.AI].BRAIN_ID}`,

      title: i18n.tc('components.suggestionStep.syncAI.brainId.title'),
      subtitle: i18n.tc('components.suggestionStep.syncAI.brainId.subTitle'),
      path: '/settings/general',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.AI}-2`,
      key: `${API_STEP_KEY[STEP_TOPIC.AI].API_KEY}`,
      title: i18n.tc('components.suggestionStep.syncAI.apiKey.title'),
      subtitle: i18n.tc('components.suggestionStep.syncAI.apiKey.subTitle'),
      path: '/settings/general',
      isCompleted: false,
    },
  ],
  [STEP_TOPIC.STARTING_BUSINESS]: [
    {
      id: `${STEP_TOPIC.STARTING_BUSINESS}-1`,
      key: `${API_STEP_KEY[STEP_TOPIC.STARTING_BUSINESS].SMS_GATEWAY}`,

      title: i18n.tc(
        'components.suggestionStep.startingBusiness.smsGateway.title'
      ),
      subtitle: i18n.tc(
        'components.suggestionStep.startingBusiness.smsGateway.subTitle'
      ),
      path: '/settings/general',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.STARTING_BUSINESS}-2`,
      key: `${API_STEP_KEY[STEP_TOPIC.STARTING_BUSINESS].GOOGLE_MAPS}`,

      title: i18n.tc(
        'components.suggestionStep.startingBusiness.googleMaps.title'
      ),
      subtitle: i18n.tc(
        'components.suggestionStep.startingBusiness.googleMaps.subTitle'
      ),
      path: '/settings/general',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.STARTING_BUSINESS}-3`,
      key: `${API_STEP_KEY[STEP_TOPIC.STARTING_BUSINESS].FLEET}`,

      title: i18n.tc('components.suggestionStep.startingBusiness.fleet.title'),
      subtitle: i18n.tc(
        'components.suggestionStep.startingBusiness.fleet.subTitle'
      ),
      path: '/fleets',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.STARTING_BUSINESS}-4`,
      key: `${API_STEP_KEY[STEP_TOPIC.STARTING_BUSINESS].DEFAULT_FLEET}`,

      title: i18n.tc(
        'components.suggestionStep.startingBusiness.defaultFleet.title'
      ),
      subtitle: i18n.tc(
        'components.suggestionStep.startingBusiness.defaultFleet.subTitle'
      ),
      path: '/organizations',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.STARTING_BUSINESS}-5`,
      key: `${API_STEP_KEY[STEP_TOPIC.STARTING_BUSINESS].SERVICE_AREA}`,

      title: i18n.tc(
        'components.suggestionStep.startingBusiness.serviceArea.title'
      ),
      subtitle: i18n.tc(
        'components.suggestionStep.startingBusiness.serviceArea.subTitle'
      ),
      path: '/geofence/service-areas',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.STARTING_BUSINESS}-6`,
      key: `${API_STEP_KEY[STEP_TOPIC.STARTING_BUSINESS].PRICING_PLAN}`,

      title: i18n.tc(
        'components.suggestionStep.startingBusiness.pricingPlan.title'
      ),
      subtitle: i18n.tc(
        'components.suggestionStep.startingBusiness.pricingPlan.subTitle'
      ),
      path: '/pricing/billing-plans',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.STARTING_BUSINESS}-7`,
      key: `${API_STEP_KEY[STEP_TOPIC.STARTING_BUSINESS].VEHICLES}`,

      title: i18n.tc(
        'components.suggestionStep.startingBusiness.vehicles.title'
      ),
      subtitle: i18n.tc(
        'components.suggestionStep.startingBusiness.vehicles.subTitle'
      ),
      path: '/vehicle-management/scooters',
      isCompleted: false,
    },
  ],
}
