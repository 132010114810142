/**
 * httpClient exports axios client with necessary defaults
 * It also exports user data => user, profile, role & org
 */

import Swal from 'sweetalert2'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
// todo: do not use deprecated config
import apiConfig from '@/config/api/config'
import { xMan } from '@/utils'
import { useEndpoints } from '@/composables/api/useEndpoints'
let user, profile, role, roles, permissions, org
let token = null
let isRefreshing = false // Flag to indicate if a token refresh is in progress
let failedRequestsQueue = [] // Queue to hold requests while the token is being refreshed

const ownerExpiredErrorMatchPart = 'your owner account has been expired'

const httpClient = axios
httpClient.defaults.baseURL = apiConfig.base
httpClient.defaults.headers.common['Accept'] =
  apiConfig.options.headers['Accept']
httpClient.defaults.headers.common['Content-Type'] =
  apiConfig.options.headers['Content-Type']

// Token & User info
;(function() {
  token = store.getters['auth/accessToken']
  if (!token) {
    httpClient.defaults.headers.common['Authorization'] = null
  } else {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

    user = store.getters['auth/userInfo']
    profile = store.getters['auth/profileInfo']
    role = store.getters['auth/roleInfo']
    org = store.getters['auth/organizationInfo']
    roles = store.getters['auth/rolesInfo']
    permissions = store.getters['auth/permissionsInfo']
  }
})()

httpClient.interceptors.request.use(
  async (config) => {
    const currentUrl = window.location.href
    const urlObject = new URL(currentUrl)
    if (urlObject.pathname === '/vehicle-in-map/') {
      const urlParams = new URLSearchParams(new URL(currentUrl).search)

      // Access the token parameter
      const token = urlParams.get('token')
      config.headers['Authorization'] = `Token ${token}`
      return config
    }
    if (!token && config?.url !== '/dashboard/admin-login-jwt/') {
      // If no token is available, wait until it's refreshed
      await waitForToken()
    }

    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

// API response interceptor
httpClient.interceptors.response.use(
  (response) => response,
  async function(error) {
    console.log('login-error', { error })
    const originalRequest = error.config
    //handle 401
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (error?.response?.data?.code === 'token_not_valid') {
        store.dispatch('auth/logout').then(() => {
          router.push('/login')
        })

        return
      }
      originalRequest._retry = true

      if (!isRefreshing) {
        isRefreshing = true
        try {
          const response = await refreshAccessToken()
          token = response.data.access
          store.commit('auth/setNewAccessToken', response.data.access)
          httpClient.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${token}`
          // Retry all queued requests with the new token
          failedRequestsQueue.forEach((req) =>
            req.resolve(httpClient(req.config))
          )
          failedRequestsQueue = []
        } catch (err) {
          failedRequestsQueue.forEach((req) => req.reject(err))
          failedRequestsQueue = []
          return Promise.reject(err)
        } finally {
          isRefreshing = false
        }
      }
      // Queue the current request and wait for the token
      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          resolve,
          reject,
          config: originalRequest,
        })
      })
    }
    // handle 403
    else if (error?.response?.status === 403) {
      // console.log('try handle', error?.response?.data)
      let errMsg = error?.response?.data

      // handle owner expired middleware response globally
      if (`${errMsg}`.toLowerCase().startsWith(ownerExpiredErrorMatchPart)) {
        if (!Swal.isVisible()) {
          Swal.fire({
            iconColor: '#fff',
            iconHtml: `<i class="fa fa-exclamation-circle text-gray-400 text-5xl" aria-hidden="true"></i>`,
            title: `Owner Account Expired`,
            text: `Contact Admin to Reset Account Expiration`,
            confirmButtonText: `Logout`,
            footer: `<a href>Why do I have this issue?</a>`,
            customClass: {
              title: '-mt-8',
              content: '-mt-2 mb-6',
              confirmButton: 'login-now-popup-button',
            },
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              store.dispatch('auth/logout')
              window.location.href = '/login'
            }
          })
        }
      }
    }
    return Promise.reject(error)
  }
)

// Function to wait until the token is available
async function waitForToken() {
  return new Promise((resolve) => {
    const checkToken = setInterval(() => {
      if (token) {
        clearInterval(checkToken)
        resolve()
      }
    }, 50)
  })
}

// Function to refresh the access token
async function refreshAccessToken() {
  const refreshToken = await localStorage.getItem('refresh_token')

  let data = new xMan({ refresh: refreshToken }).toFormData()

  return httpClient.post(useEndpoints.auth.resetAccessToken(), data)
}

export { httpClient, user, profile, role, roles, permissions, org }
export default httpClient
