export default {
  namespaced: true,

  state: {
    isSmallScreen: false,
  },
  getters: {
    getSmallScreenStatus(state) {
      return state.isSmallScreen
    },
  },
  mutations: {
    SET_SMALL_SCREEN_STATUS(state, value) {
      state.isSmallScreen = value
    },
  },
}
