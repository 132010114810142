export const GOOGLE_ANALYTICS = Object.freeze({
  OTO_ADMIN_SIDEBAR_NAVIGATE: {
    TAG: 'oto_admin_sidebar_navigate',
    CATEGORY: 'Navigation',
  },
  OTO_ADMIN_DASHBOARD_ACTIVE_VEHICLE_BY_HOURS: {
    TAG: 'oto_admin_active_vehicle_by_hours',
    CATEGORY: 'DASHBOARD_PAGE',
  },
})
