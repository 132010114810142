import Vue from 'vue'
import {
  httpClient,
  user,
  profile,
  role,
  roles,
  permissions,
  org,
} from '@/services'

Vue.prototype.$http = httpClient

Vue.prototype.$user = user
Vue.prototype.$profile = profile
Vue.prototype.$role = role
Vue.prototype.$roles = roles
Vue.prototype.$permissions = permissions
Vue.prototype.$org = org
