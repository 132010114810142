export default {
  api: {
    // get
    index: '/dashboard/sunpod-areas/',
    // post
    create: '/dashboard/sunpod-areas/',
    // patch
    update: (sunpodStationId) => `/dashboard/sunpod-areas/${sunpodStationId}/`,
    // delete
    delete: (sunpodStationId) => `/dashboard/sunpod-areas/${sunpodStationId}/`,
    // get
    details: (sunpodStationId) => `/dashboard/sunpod-areas/${sunpodStationId}/`,
  },
  events: {
    name: 'sunpod-station-area',
    // refresh-index-data
    refresh: `rid-sunpod-station-area`,
    // slideover-right
    sorId: 'sunpod-station-area',
    sorOpen: 'sor-open-sunpod-station-area',
    sorClose: 'sor-close-sunpod-station-area',
    sorToggle: 'sor-toggle-sunpod-station-area',
    // editing-data
    editingData: 'edit-sunpod-station-area-data',
    // viewing-data
    viewingData: 'view-sunpod-station-area-data',
  },
}
