import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { locales } from '@/config'
// import store from '@/store'
Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context(
    '@/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
//it's cause an issue-- couldn't translate from js file
// function getPreferredLanguage() {
//   if (store.getters['auth/profileInfo']) {
//     const preferredLanguage = store.getters['auth/profileInfo'].language
//       ? store.getters['auth/profileInfo'].language.code2
//       : locales.DEFAULT_LOCALE
//     console.log('preferredLanguage-p', preferredLanguage)
//     return preferredLanguage
//   } else {
//     console.log('preferredLanguage-o-p', locales.DEFAULT_LOCALE)
//     return locales.DEFAULT_LOCALE
//   }
// }
console.log()
export default new VueI18n({
  locale: JSON.parse(localStorage.getItem('profile'))
    ? JSON.parse(localStorage.getItem('profile')).language
      ? JSON.parse(localStorage.getItem('profile')).language.code2
      : locales.DEFAULT_LOCALE
    : locales.DEFAULT_LOCALE,

  fallbackLocale: locales.FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
})
