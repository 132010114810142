import Vue from 'vue'
import '@/components/global'
// wip: convert filters into helpers / plugins & never use filters
import '@/utils/Filters'
import '@/plugins'

import App from './App.vue'
import i18n from '@/plugins/i18n'
import router from './router'
import store from './store'
import { mqInstance } from '@/plugins/media-query'

// wip: use mitt for events
export const eventBus = new Vue()

router.afterEach((to) => {
  // Ensure Google Analytics is loaded
  if (window.gtag) {
    // Track page view
    window.gtag('config', 'G-YZFYL5JDQF', {
      page_path: to.fullPath,
    })
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  mediaQueries: mqInstance,
  render: (h) => h(App),
}).$mount('#app')

let magnusAiScript = document.createElement('script')
magnusAiScript.setAttribute(
  'src',
  `${process.env.VUE_APP_MAGNUSAI_API_URL}/api/v1/chat_widget/script/chat.js/${process.env.VUE_APP_MAGNUSAI_BRAIN_ID}/`
)
magnusAiScript.setAttribute('defer', '')

document.body.appendChild(magnusAiScript)
