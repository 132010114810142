import Fuse from 'fuse.js'
import chunk from 'lodash/chunk'
import { byDate, byNumber, byValue } from 'sort-es'

export default {
  getResData(state) {
    const data = { ...state.resData }
    return data
  },

  getFleetsData(state) {
    const data = { ...state.fleetsData }
    // delete data.__tracker
    return data
  },

  // ds: uuid:count
  getFleetVehicleCount(state) {
    const map = {}

    const vehicles = state.resData
    const fleetIds = Object.keys(state.fleetsData)

    fleetIds.forEach((id) => {
      map[id] = 0
    })

    // todo: add a fleet id for un-associated vehicles

    for (const vehicleId in vehicles) {
      const v = vehicles[vehicleId]
      // validate we have it mapped already with the count of 0
      if (typeof map[v?.fleet?.id] === 'number') {
        map[v.fleet.id] += 1
      }
    }

    return map
  },

  getFilterFleet(state) {
    const data = { ...state.filters.filterFleet }
    delete data.__tracker
    return data
  },

  getFilterFleetSelected(state, getters) {
    // ds: {uuid: bool,..}
    const filterFleet = { ...getters.getFilterFleet.models }

    for (const key in filterFleet) {
      if (filterFleet[key] !== true) {
        delete filterFleet[key]
      }
    }

    return filterFleet
  },

  getFilteredFleetVehicles(state, getters) {
    const vehicles = { ...getters.getResData }
    const filterableFleets = { ...getters.getFilterFleetSelected }

    // if there's no fleet filter, return all vehicles
    if (Object.keys(filterableFleets).length === 0) return vehicles

    // or return filtered vehicles -> based on filtered vehicles, other filters will propagate
    const filteredVehicles = {}
    for (const key in vehicles) {
      // loop through enabled fleet filter
      const vehicleFleetId = vehicles[key]?.fleet?.id
      // some vehicle might not be associated with a fleet
      if (typeof vehicleFleetId === 'string') {
        for (const fleetId in filterableFleets) {
          if (fleetId === vehicleFleetId) {
            filteredVehicles[key] = vehicles[key]
          }
        }
      }
    }
    return filteredVehicles
  },
  getRealtimeFilterStatus(state) {
    return state.realtimeFilterStatus
  },

  getFilteredFleetSummary(state, getters) {
    const fleets = state.fleetsData
    const fleetFilterables = getters.getFilterFleetSelected
    const fleetFilterablesCount = Object.keys(fleetFilterables).length

    let fleetName = ''
    // get the only applied fleet filter name
    for (const k in fleetFilterables) {
      fleetName = fleets[k].name
      break
    }

    const vehicles = { ...state.filteredFleet }
    const total = Object.keys(vehicles).length

    // todo: improve counts
    const filteredVehicles = getters.getVehicleList

    // todo: move operational & non operational to iot{}
    // todo: move rest items to type{}
    let operational = 0,
      nonOperational = 0,
      bike = 0,
      eBike = 0,
      scooter = 0

    const iot = {
      operational: 0,
      nonOperational: 0,

      locked: 0,
      unlocked: 0,
    }

    const status = {
      available: {
        count: 0,
      },
      unavailable: {
        count: 0,
      },
      maintenance: {
        count: 0,
      },
      rebalance: {
        count: 0,
      },
      swapBattery: {
        count: 0,
      },
    }

    let htmlSentence = `<span class="font-bold text-green-600">${total}</span> vehicles in <span class="font-bold text-oDark"> `

    htmlSentence +=
      fleetFilterablesCount === 0
        ? `All</span> locations`
        : fleetFilterablesCount === 1
        ? `${fleetName}</span>`
        : `${fleetFilterablesCount}</span> locations`
    let headlineData = {
      totalVehicle: total,
      fleetCount: fleetFilterablesCount,
      fleetName: fleetName,
    }

    // todo: rename to flags?
    let summary = {
      onIdle: {
        key: 'onIdle',
        text: 'On Idle',
        count: 0,
        percentage: 0,
      },
      onTrip: {
        key: 'onTrip',
        text: 'On Rent',
        count: 0,
        percentage: 0,
      },
      onReservation: {
        key: 'onReservation',
        text: 'On Reservation',
        count: 0,
        percentage: 0,
      },
      onParking: {
        key: 'onParking',
        text: 'On Parking',
        count: 0,
        percentage: 0,
      },
      onIllegallyParked: {
        key: 'onIllegallyParked',
        text: 'Illegally Parked',
        count: 0,
        percentage: 0,
      },
      onMissing: {
        key: 'onMissing',
        text: 'Missing',
        count: 0,
        percentage: 0,
      },
      onCharging: {
        key: 'onCharging',
        text: 'On Charging',
        count: 0,
        percentage: 0,
      },
      onChargingPick: {
        key: 'onChargingPick',
        text: 'Charging Pick',
        count: 0,
        percentage: 0,
      },
      onMaintenance: {
        key: 'onMaintenance',
        text: 'On Maintenance',
        count: 0,
        percentage: 0,
      },
      onRebalance: {
        key: 'onRebalance',
        text: 'Rebalance',
        count: 0,
        percentage: 0,
      },
      onIOTFault: {
        key: 'onIOTFault',
        text: 'IOT Fault',
        count: 0,
        percentage: 0,
      },
      onGeoFenceAlert: {
        key: 'onGeoFenceAlert',
        text: 'GeoFence Alert',
        count: 0,
        percentage: 0,
      },
      onLowBattery: {
        key: 'onLowBattery',
        text: 'Low Battery',
        count: 0,
        percentage: 0,
      },
      onMediumBattery: {
        key: 'onMediumBattery',
        text: 'Medium Battery',
        count: 0,
        percentage: 0,
      },
      onHighBattery: {
        key: 'onHighBattery',
        text: 'High Battery',
        count: 0,
        percentage: 0,
      },
    }

    for (const k in filteredVehicles) {
      const v = vehicles[k]
      if (!v) {
        console.error(`getCountLoop: the key '${v}' is not found`)
        continue
      }

      // type
      if (v.bike_category === 'S') {
        bike += 1
      } else if (v.bike_category === 'E') {
        eBike += 1
      } else if (v.bike_category === 'P') {
        scooter += 1
      }

      // lock todo: move to iot
      if (v.lock.is_operational) {
        operational += 1
      } else {
        nonOperational += 1
      }

      if (v.lock.is_locked) {
        iot.locked += 1
      } else {
        iot.unlocked += 1
      }

      const powerLevel = parseFloat(v.lock.power_level).toFixed()
      if (powerLevel <= 29) {
        summary.onLowBattery.count += 1
      } else if (powerLevel >= 30 && powerLevel <= 69) {
        summary.onMediumBattery.count += 1
      } else if (powerLevel >= 70) {
        summary.onHighBattery.count += 1
      }

      // mode
      if (v.vehicleMode === 'AV') {
        status.available.count += 1
      } else if (v.vehicleMode === 'UV') {
        status.unavailable.count += 1
      } else if (v.vehicleMode === 'MT') {
        status.maintenance.count += 1
      } else if (v.vehicleMode === 'RB') {
        status.rebalance.count += 1
      } else if (v.vehicleMode === 'SB') {
        status.swapBattery.count += 1
      }

      // status flags
      const sf = v.status_flags
      sf.is_idle && (summary.onIdle.count += 1)
      sf.is_on_ride && (summary.onTrip.count += 1)
      sf.is_reserved && (summary.onReservation.count += 1)
      sf.is_charging && (summary.onCharging.count += 1)
      sf.is_parking && (summary.onParking.count += 1)
      sf.is_illegally_parking && (summary.onIllegallyParked.count += 1)

      // general flags
      const gf = v.general_flags
      gf.missing && (summary.onMissing.count += 1)
      gf.iot_fault && (summary.onIOTFault.count += 1)
      gf.geo_fence_alert && (summary.onGeoFenceAlert.count += 1)

      // operational flags
      const of = v.operational_flags
      of.rebalance && (summary.onRebalance.count += 1)
      of.charging_pick && (summary.onChargingPick.count += 1)
      of.maintainance && (summary.onMaintenance.count += 1)
    }

    if (total > 0) {
      for (const group in summary) {
        // console.log('ss', k)
        const k = summary[group]
        const p = (k.count * 100) / total
        k.percentage = p.toFixed()
      }

      // sort
      const values = Object.values(summary)

      summary = {}
      values.sort((a, b) => {
        return a.count < b.count ? 1 : a.count > b.count ? -1 : 0
      })

      values.forEach((el) => {
        summary[el.key] = el
      })
    }

    return {
      total,
      htmlSentence,
      headlineData,
      type: {
        bike,
        eBike,
        scooter,
      },
      iot,
      status,
      connectivity: {
        operational,
        nonOperational,
      },
      summary,
    }
  },

  getFilterDataForPrediction(state) {
    const { filterFleet, filterLocationPoint } = state.filters
    return { filterFleet, filterLocationPoint }
  },

  getFilteredData(state, getters) {
    const { bike, eBike, scooter } = state.filters.filterVehicleType.models
    const {
      operational,
      nonOperational,
    } = state.filters.filterConnectivity.models
    const { locked, unlocked } = state.filters.filterLockStatus.models
    const {
      available,
      unavailable,
      rebalance,
      maintenance,
      swapBattery,
    } = state.filters.filterVehicleMode.models
    const { low, medium, high } = state.filters.filterBatteryStatus.models
    const {
      onTrip,
      onReservation,
      onCharging,
      onParking,
      onIllegallyParked,
      onGeoFenceAlert,
    } = state.filters.filterVehicleStatus.models

    const itHasVehicleTypeFilter = bike || eBike || scooter
    const itHasConnectivityFilter = operational || nonOperational
    const itHasLockStatusFilter = locked || unlocked
    const itHasVehicleModeFilter =
      available || unavailable || rebalance || maintenance || swapBattery
    const itHasBatteryStatusFilter = low || medium || high
    const itHasVehicleStatusFilter =
      onTrip ||
      onReservation ||
      onCharging ||
      onParking ||
      onIllegallyParked ||
      onGeoFenceAlert

    let vehicles = { ...getters.getResData }

    const filterableFleets = getters.getFilterFleetSelected
    const filterableFleetsCount = Object.keys(filterableFleets).length
    // if there's no fleet filter, use all vehicles
    // or use fleet filtered vehicles -> based on filtered vehicles, other filters will propagate
    let filteredVehicles = { ...vehicles }

    if (filterableFleetsCount > 0) {
      let fleetVehicles = {}
      // const filteredVehicles1 = {}

      for (const key in vehicles) {
        const vehicleFleetId = vehicles[key]?.fleet?.id
        // some vehicles might not be associated with a fleet
        if (typeof vehicleFleetId === 'string') {
          // loop through enabled fleet filter
          for (const fleetId in filterableFleets) {
            if (fleetId === vehicles[key].fleet.id) {
              fleetVehicles[key] = vehicles[key]
            }
          }
        }
      }

      filteredVehicles = fleetVehicles
      console.log('Fleet Filter Applied')
      // console.log('fleet', { fleetVehicles })
    }

    let filteredVehicleType = {}
    if (itHasVehicleTypeFilter) {
      for (const key in filteredVehicles) {
        if (bike && filteredVehicles[key].bike_category === 'S') {
          filteredVehicleType[key] = filteredVehicles[key]
        }

        if (eBike && filteredVehicles[key].bike_category === 'E') {
          filteredVehicleType[key] = filteredVehicles[key]
        }

        if (scooter && filteredVehicles[key].bike_category === 'P') {
          filteredVehicleType[key] = filteredVehicles[key]
        }
      }
      console.log('Vehicle Type Filter Applied')
      filteredVehicles = filteredVehicleType
    }

    let filteredConnectivity = {}
    if (itHasConnectivityFilter) {
      for (const key in filteredVehicles) {
        if (operational && filteredVehicles[key].lock.is_operational) {
          filteredConnectivity[key] = filteredVehicles[key]
        }

        if (nonOperational && !filteredVehicles[key].lock.is_operational) {
          filteredConnectivity[key] = filteredVehicles[key]
        }
      }
      console.log('Connectivity Filter Applied')
      filteredVehicles = filteredConnectivity
    }

    const filteredLockStatus = {}
    if (itHasLockStatusFilter) {
      for (const key in filteredVehicles) {
        if (locked && filteredVehicles[key].lock.is_locked) {
          filteredLockStatus[key] = vehicles[key]
        }

        if (unlocked && !filteredVehicles[key].lock.is_locked) {
          filteredLockStatus[key] = vehicles[key]
        }
      }
      console.log('Lock Filter Applied')

      filteredVehicles = filteredLockStatus
    }

    const filteredVehicleMode = {}
    if (itHasVehicleModeFilter) {
      for (const key in filteredVehicles) {
        if (available && vehicles[key].vehicleMode === 'AV') {
          filteredVehicleMode[key] = filteredVehicles[key]
        }
        if (unavailable && vehicles[key].vehicleMode === 'UV') {
          filteredVehicleMode[key] = filteredVehicles[key]
        }
        if (rebalance && vehicles[key].vehicleMode === 'RB') {
          filteredVehicleMode[key] = filteredVehicles[key]
        }
        if (maintenance && vehicles[key].vehicleMode === 'MT') {
          filteredVehicleMode[key] = filteredVehicles[key]
        }
        if (swapBattery && vehicles[key].vehicleMode === 'SB') {
          filteredVehicleMode[key] = filteredVehicles[key]
        }
      }

      console.log('Mode Filter Applied')

      filteredVehicles = filteredVehicleMode
    }

    const filteredBatteryStatus = {}
    if (itHasBatteryStatusFilter) {
      // console.log('battery', itHasBatteryStatusFilter, low, medium, high)
      for (const key in filteredVehicles) {
        const vehicle = filteredVehicles[key]
        const powerLevel = parseFloat(vehicle?.lock?.power_level).toFixed() || 0

        // console.log(`${vehicle.name} -- ${powerLevel}`)

        if (low && powerLevel >= 0 && powerLevel <= 29) {
          // console.log(`Low ${vehicle.name} -- ${powerLevel}`)
          filteredBatteryStatus[key] = vehicle
        }
        if (medium && powerLevel >= 30 && powerLevel <= 69) {
          // console.log(`Med ${vehicle.name} -- ${powerLevel}`)
          filteredBatteryStatus[key] = vehicle
        }
        if (high && powerLevel >= 70) {
          // console.log(`high ${vehicle.name} -- ${powerLevel}`)
          filteredBatteryStatus[key] = vehicle
        }
      }
      console.log('Battery Filter Applied')

      filteredVehicles = filteredBatteryStatus
    }

    const filteredVehicleStatus = {}
    if (itHasVehicleStatusFilter) {
      for (const key in filteredVehicles) {
        const v = vehicles[key].status_flags
        if (onTrip && v.is_on_ride) {
          filteredVehicleStatus[key] = filteredVehicles[key]
        }
        if (onReservation && v.is_reserved) {
          filteredVehicleStatus[key] = filteredVehicles[key]
        }
        if (onCharging && v.is_charging) {
          filteredVehicleStatus[key] = filteredVehicles[key]
        }
        if (onParking && v.is_parking) {
          filteredVehicleStatus[key] = filteredVehicles[key]
        }
        if (onIllegallyParked && v.is_illegally_parking) {
          filteredVehicleStatus[key] = filteredVehicles[key]
        }
        if (onGeoFenceAlert && vehicles[key].general_flags.geo_fence_alert) {
          filteredVehicleStatus[key] = filteredVehicles[key]
        }
      }

      console.log('Status Filter Applied')
      filteredVehicles = filteredVehicleStatus
    }
    return filteredVehicles
  },

  getVehicleList(state) {
    // const data = new Map(state.drawer.list)
    // data.delete('__tracker')

    const sortBy = state.filteredSortByActive
    const desc = state.filteredSortBy[sortBy] === 'desc' ? true : false

    console.log(sortBy, desc)

    const data = { ...state.list }
    delete data.__tracker

    let sorted
    const toArray = Object.values(data)

    if (sortBy === 'qrCode') {
      sorted = toArray.sort(byValue((i) => i.qr_code, byNumber({ desc })))
    } else if (sortBy === 'battery') {
      sorted = toArray.sort(
        byValue((i) => i.lock.power_level, byNumber({ desc }))
      )
    } else if (sortBy === 'lastMoved') {
      sorted = toArray.sort(
        byValue(
          (i) => i.last_loc_updated_at || Date('December 1, 1975'),
          byDate({ desc })
        )
      )
    }

    if (sorted) {
      const result = {}
      sorted.forEach((el) => (result[el.id] = el))
      return result
    }

    return data
  },

  getVehicleListChunks(state, getters) {
    const chunks = []
    const vIndex = state.listViewingIndex
    const chunked = chunk(Object.values(getters.getVehicleList), 50)
    // console.log({ chunked })

    for (let i = 0; i <= vIndex; i++) {
      chunks.push(chunked[i])
    }

    // console.log({ chunks })
    return chunks
  },

  getVehicleListSearched(state, getters) {
    // const data = new Map(state.drawer.list)
    // data.delete('__tracker')
    const data = { ...getters.getVehicleList }
    const query = state.filteredSearch
    // const result = {}

    if (query) {
      const fuse = new Fuse(Object.values(data), {
        keys: ['name', 'qr_code'],
      })

      const res = fuse.search(query, { limit: 10 })
      const map = {}
      res.forEach((item) => {
        map[item.item.id] = item.item
      })

      return map
    }
    return data
  },

  getVehicleListCount(state, getters) {
    return Object.keys(getters.getVehicleList).length || 0
  },

  getVehicleListSelected(state) {
    const data = {}

    for (const key in state.list) {
      if (state.list[key].isSelected) {
        data[key] = state.list[key]
      }
    }

    return data
  },

  getVehicleListSelectedCount(_state, getters) {
    const k = Object.keys(getters.getVehicleListSelected)
    return k.length
  },
  getSelectedVehicleId(state) {
    return state.selectedVehicleId
  },
  getSelectedVehiclesRealtimeLocation(state) {
    return state.realtimeDataOfSelectedVehicle
  },
  getTotalChunkedDataCount(_state, getters) {
    if (getters.getVehicleListChunks.length > 0) {
      const chunks = getters.getVehicleListChunks
      const innerCountsOfChunks = Object.keys(chunks).map((item) => {
        if (chunks[item]) {
          return chunks[item].length
        } else {
          return 0
        }
      })
      const totalChunkedData = innerCountsOfChunks.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
      return totalChunkedData
    } else {
      return 0
    }
  },
}
